import { jwtDecode } from "jwt-decode";
import { Useprovider } from "./stateManagementAPi";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotificationServices from "./topnavServices";
import { truncateName25, truncateName40 } from "../helper/StringSlicer";
import { formatDateWithTime } from "../helper/DateFormater";
import NotificaionServices from "./module/admin/notification/services";

const TopNav = () => {
  // const [notificationCount, setNotificationCount] = useState();
  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificaionCount] = useState(0);
  const [notificationData, setNotificationData] = useState({});
  const token = localStorage.getItem("token");
  const jwt = token ? jwtDecode(token) : null;
  const userId = jwt?.id;
  const workforceId = jwt?.workforceId;
  console.log("token_user_Id", userId);

  //  const jwt = jwtDecode(localStorage.getItem("token"));
  const navigate = useNavigate();
  const { path } = useContext(Useprovider);
  const role = jwt?.role[0]?.name;
  console.log("jwt_role", role);
  function logOut() {
    localStorage.removeItem("token");
    if (localStorage.getItem("token") == null) {
      navigate("/");
    }
  }

  useEffect(() => {
    getNotification();
  }, []);

  //get notification
  const getNotification = () => {
    NotificaionServices.getNotification()
      .then((response) => {
        const data = response.data.data.notificationList;
        setNotification(data);
        setNotificaionCount(response.data.data.notificationCount);
        console.log("setNotification", data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNotification = (id) => {
    debugger;
    NotificaionServices.findNotificationById(id)
      .then((response) => {
        setNotificationData(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      {jwt !== null ? (
        <header className="top-header">
          <nav className="navbar navbar-expand">
            <div className="mobile-toggle-icon d-xl-none">
              <i className="bi bi-list"></i>
            </div>
            <div className="row width-100">
              <div className="col-6 col-lg-6 col-xl-6"></div>

              <div className="col-6 col-lg-6 col-xl-6">
                <div className="top-navbar-right ms-3 float-right">
                  <ul className="navbar-nav align-items-center">
                    <li className="nav-item dropdown dropdown-large">
                      <a
                        className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <div className="notifications">
                          <span className="notify-badge">
                            {notificationCount}
                          </span>
                          <i className="bi bi-bell-fill"></i>
                        </div>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end p-0">
                        <div className="p-2 border-bottom m-2">
                          <h5 className="h5 mb-0">Notifications</h5>
                        </div>
                        <div className="header-notifications-list p-2">
                          {notification.slice(0, 6).map(
                            (
                              item // Limit to the first five notifications
                            ) => (
                              <a
                                style={{ cursor: "pointer" }}
                                key={item.id} // Ensure each element has a unique key
                                className={`dropdown-item ${
                                  item.status === "RECEIVED" ? "active-bg" : "" // Check if status is "RECEIVED"
                                }`}
                                onClick={() => handleNotification(item.id)}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="notification-box">
                                    <i className="bi bi-people-fill"></i>
                                  </div>
                                  <div className="ms-2 flex-grow-1 w-90">
                                    <p
                                      className="mb-0 dropdown-msg-user font_12"
                                      style={{ fontWeight: 500 }}
                                    >
                                      {item?.title}
                                      <span
                                        title="2023-01-12 11:54:14Z"
                                        className="relativetime float-end font_11" // Corrected class to className
                                      >
                                        {formatDateWithTime(item.timestamp)}
                                      </span>
                                    </p>
                                    <small
                                      className="mb-0 dropdown-msg-text text-secondary d-flex align-items-center font_10"
                                      style={{ whiteSpace: "break-spaces" }}
                                    >
                                      {truncateName40(item?.description)}
                                    </small>
                                  </div>
                                </div>
                              </a>
                            )
                          )}
                        </div>
                        <div className="p-2">
                          <div>
                            <hr className="dropdown-divider" />
                          </div>
                          {(role === "ROLE_WORKER" ||
                            role === "ROLE_SUPERVISOR") && (
                            <a
                              className="dropdown-item text-center"
                              href="/dashboard/supervisor/notification"
                            >
                              View All Notifications
                            </a>
                          )}

                          {role === "ROLE_ADMIN" && (
                            <a
                              className="dropdown-item text-center"
                              href="/dashboard/company/notification"
                            >
                              View All Notifications
                            </a>
                          )}
                          {role === "ROLE_SUPERADMIN" && (
                            <a
                              className="dropdown-item text-center"
                              href="/dashboard/admin/notification"
                            >
                              View All Notifications
                            </a>
                          )}
                        </div>
                      </div>
                    </li>
                    <li className="nav-item dropdown dropdown-large">
                      <a
                        className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <div className="user-setting d-flex align-items-center gap-1">
                          <img
                            src="/assets/img/favicon.png"
                            className="user-img"
                            alt=""
                          />
                          <div className="user-name">{jwt.name}</div>
                        </div>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <a className="dropdown-item">
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/img/favicon.png"
                                alt=""
                                className="rounded-circle"
                                width="60"
                                height="60"
                              />
                              <div className="ms-3">
                                <h6 className="mb-0 dropdown-user-name">
                                  {jwt.name}
                                </h6>
                                {jwt.userType === "worker" ? (
                                  <small className="mb-0 dropdown-user-designation text-secondary">
                                    You are not authorize
                                  </small>
                                ) : (
                                  <small className="mb-0 dropdown-user-designation text-secondary">
                                    {jwt.userType}
                                  </small>
                                )}
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        {role == "ROLE_SUPERADMIN" || role == "ROLE_ADMIN" ? (
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/dashboard/admin/changepassword"
                              state={{ userName: jwt.username }}
                            >
                              <div className="d-flex align-items-center">
                                <div className="setting-icon">
                                  <i className="bi bi-repeat fill"></i>
                                </div>
                                <div className="setting-text ms-3">
                                  <span>Change Password</span>
                                </div>
                              </div>
                            </Link>
                          </li>
                        ) : (
                          <>
                            <li>
                              <a
                                className="dropdown-item"
                                href="/dashboard/supervisor/myProfile"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="setting-icon">
                                    <i className="bi bi-person-fill"></i>
                                  </div>
                                  <div className="setting-text ms-2">
                                    <span>Profile</span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="/dashboard/supervisor/settings"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="setting-icon">
                                    <i className="bi bi-gear-fill"></i>
                                  </div>
                                  <div className="setting-text ms-2">
                                    <span>Settings</span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="/dashboard/supervisor/changepassword"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="setting-icon ">
                                    <i className="bi bi-repeat fill"></i>
                                  </div>
                                  <div className="setting-text ms-2">
                                    <span>Change Password</span>
                                  </div>
                                </div>
                              </a>
                            </li>

                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                          </>
                        )}
                        <li>
                          <button className="dropdown-item" onClick={logOut}>
                            <div className="d-flex align-items-center">
                              <div className="setting-icon">
                                <i className="bi bi-lock-fill"></i>
                              </div>
                              <div className="setting-text ms-3">
                                <span>Logout</span>
                              </div>
                            </div>
                          </button>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
      ) : null}
    </>
  );
};

export default TopNav;
