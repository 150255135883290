import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { jwtDecode } from "jwt-decode";
import ViewDetails from "../companies/viewDetails";
import AdminApi from "./services";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import CompaniesApi from "../companies/services";

//get companyId from token

const AdminHome = () => {
  const [totalOrganisations, setTotalOrganisation] = useState(0);
  const [totalPaymentReceived, setPaymentReceived] = useState(0);
  const [totalExpiredSubscription, setExpiredSubscription] = useState(0);

  const [freePlans, setFreePlans] = useState("");
  const [startupPlans, setStartupPlans] = useState("");
  const [professionalPlan, setProfessionalPlan] = useState("");
  const [enterprisePlan, setEnterprisePlan] = useState("");

  const [requests, setRequests] = useState([]);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const jwt = jwtDecode(localStorage.getItem("token"));
  const role = jwt.userType;
  const companyId = jwt.companyId;
  const userId = jwt.id;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (role === "ADMIN") {
      navigate("/dashboard/company/home");
    } else if (role === "SUPERVISOR") {
      navigate("/dashboard/supervisor/home");
    }
    // else {
    //   navigate("/dashboard/admin/home")
    // }
    console.log("Role", role);
  }, []);

  useEffect(() => {
    getDashboardData();
    getStaticPlans();
    getConstructionRequests();
  }, []);

  //GET DASHBORAD DATA
  const getDashboardData = async () => {
    debugger;
    setLoad(true);
    try {
      debugger;
      const resp = await AdminApi.getAdminDashboard();
      if (resp.status === 200) {
        const data = resp?.data?.data;
        console.log("dashboardCount", data);
        setPaymentReceived(data?.totalPaymentReceived);
        setExpiredSubscription(data?.totalExpiredSubscription);
        setTotalOrganisation(data?.totalActiveOrg);
        setCount(1);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 404) {
          console.log("404 - " + error.response.data.message);
        } else if (status === 400) {
          console.log("400 - " + error.response.data.message);
        } else if (status >= 500) {
          console.log("500 - " + error.response.data.message);
        }
      } else {
        console.log("Error", error.message);
      }
    } finally {
      setLoad(false);
    }
  };

  const getStaticPlans = () => {
    setLoad(true);
    AdminApi.getStaticPlans()
      .then((response) => {
        if (response.status === 200) {
          console.log("getCompanyStatics", response.data);
          setFreePlans(response.data.Free);
          setStartupPlans(response.data.Startup);
          setProfessionalPlan(response.data.Professional);
          setEnterprisePlan(response.data.Enterprise);
          setCount(1);
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            console.log("404 - " + error.response.message);
          } else if (error.response.status === 400) {
            console.log("400 - " + error.response.message);
          } else if (error.response.status >= 500) {
            console.log("500 - " + error.response.message);
          }
        } else {
          console.log("Error", error.message);
        }
        setLoad(false);
      });
  };

  //get construction request
  const getConstructionRequests = () => {
    debugger;
    setLoad(true);
    AdminApi.getConstructionRequestsTop3()
      .then((response) => {
        if (response.status === 200) {
          debugger;
          const data = response.data.data;
          console.log("getCompanyRequest", response.data.data);
          setRequests(data);
          console.log("request_data", data);
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            console.log("404 - " + error.response.message);
          } else if (error.response.status === 400) {
            console.log("400 - " + error.response.message);
          } else if (error.response.status >= 500) {
            console.log("500 - " + error.response.message);
          }
        } else {
          console.log("Error", error.message);
        }
        setLoad(false);
      });
  };

  // company details

  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnRejectLoading, setRejectBtnLoading] = useState(false);
  const [action, setAction] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");

  const [loadingStates, setLoadingStates] = useState({});

  // Approve function (updates the loading state for the clicked item)
  function approve(email) {
    setLoadingStates((prevState) => ({
      ...prevState,
      [email]: { approveLoading: true },
    }));
    setAction("approve");
    CompaniesApi.changeStatus(email, true)
      .then((response) => {
        setShow(false);
        setMessage(response.data.message);
        setSeverity("success");
        setOpen(true);
        getConstructionRequests();
      })
      .catch((error) => {
        // Handle error (if any)
      })
      .finally(() => {
        setLoadingStates((prevState) => ({
          ...prevState,
          [email]: { approveLoading: false },
        }));
        setAction("");
      });
  }

  // Reject function (same logic as approve)
  function reject() {
    setLoadingStates((prevState) => ({
      ...prevState,
      [rejectEmailId]: { rejectLoading: true },
    }));
    setAction("reject");
    CompaniesApi.changeStatus(rejectEmailId, false, rejectionReason)
      .then((response) => {
        setShow(false);
        setMessage(response.data.message);
        setSeverity("success");
        setOpen(true);
        setRejectionReason("");
        getConstructionRequests();
      })
      .catch((error) => {
        // Handle error (if any)
      })
      .finally(() => {
        setLoadingStates((prevState) => ({
          ...prevState,
          [rejectEmailId]: { rejectLoading: false },
        }));
        setAction("");
      });
  }

  // function reject() {
  //   setRejectBtnLoading(true);
  //   CompaniesApi.changeStatus(modelData.email, false, rejectionReason)
  //     .then((response) => {
  //       setShow(false);
  //       setMessage(response.data.message);
  //       setSeverity("success");
  //       setOpen(true);
  //       setRejectBtnLoading(false);
  //       setRejectionReason(""); // Clear reason after use
  //     })
  //     .catch((error) => {
  //       setRejectBtnLoading(false);
  //       setAction("");
  //     });
  // }
  const handleReasonModalClose = () => setReasonModalOpen(false);
  const handleReasonModalOpen = () => setReasonModalOpen(true);

  const [rejectEmailId, setRejectEmailId] = useState("");
  const handleRejectWithReason = (rejectEmail) => {
    handleReasonModalOpen();
    setRejectEmailId(rejectEmail);
  };

  const handleConfirmReject = () => {
    reject();
    handleReasonModalClose();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <h3 className="text-blue mb-4">
            {t("Hey")}, TAPP ME{" "}
            <span className="font-18 text-gray">
              {t("Welcome To The Portal!")}
            </span>
          </h3>
          <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 home-box mt-4">
            <div className="col">
              <div className="card radius-10">
                <div className="card-body py-4">
                  <div className="align-items-center">
                    <div>
                      <p className="mb-3 text-white font-18">
                        {t("Total Active Organizations")}
                      </p>
                      <h2 className="my-1 text-white">
                        {count > 0 ? (
                          load ? (
                            <CircularProgress color="inherit" />
                          ) : (
                            totalOrganisations
                          )
                        ) : (
                          0
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10">
                <div className="card-body py-4">
                  <div className="align-items-center">
                    <div>
                      <p className="mb-3 text-white font-18">
                        {t("Total Payment Received")}
                      </p>
                      <h2 className="my-1 text-white">
                        {count > 0 ? (
                          load ? (
                            <CircularProgress color="inherit" />
                          ) : (
                            totalPaymentReceived
                          )
                        ) : (
                          0
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10">
                <div className="card-body py-4">
                  <div className="align-items-center">
                    <div>
                      <p className="mb-3 text-white font-18">
                        {t("Total Expired Subscription")}
                      </p>
                      <h2 className="my-1 text-white">
                        {count > 0 ? (
                          load ? (
                            <CircularProgress color="inherit" />
                          ) : (
                            totalExpiredSubscription
                          )
                        ) : (
                          0
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-12 col-xl-12 mt-4">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <label className="font-20">{t("Total Plans Sold")}</label>
              <div className="row row-cols-1 row-cols-md-4 row-cols-lg-4 row-cols-xl-4 mt-4 admin">
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center text-center">
                        <div>
                          <p className="mb-3 font-20">{t("Free Plan")}</p>
                          <p className="mb-0 font-24 text-blue">
                            {count > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                freePlans
                              )
                            ) : (
                              0
                            )}
                          </p>

                          <p className="my-1 font-14 text-gray">
                            {t("plans sold")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center text-center">
                        <div>
                          <p className="mb-3 font-20">{t("Startup Plan")}</p>
                          <p className="mb-0 font-24 text-blue">
                            {count > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                startupPlans
                              )
                            ) : (
                              0
                            )}
                          </p>
                          <p className="my-1 font-14 text-gray">
                            {t("plans sold")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center text-center">
                        <div>
                          <p className="mb-3 font-20">
                            {t("Professional Plan")}
                          </p>
                          <p className="mb-0 font-24 text-blue">
                            {count > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                professionalPlan
                              )
                            ) : (
                              0
                            )}
                          </p>
                          <p className="my-1 font-14 text-gray">
                            {t("plans sold")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card radius-10">
                    <div className="card-body py-4">
                      <div className="align-items-center text-center">
                        <div>
                          <p className="mb-3 font-20">{t("Enterprise Plan")}</p>
                          <p className="mb-0 font-24 text-blue">
                            {count > 0 ? (
                              load ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                enterprisePlan
                              )
                            ) : (
                              0
                            )}
                          </p>
                          <p className="my-1 font-14 text-gray">
                            {t("plans sold")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-lg-5">
              <div className="row row-cols-1 g-1 align-items-center pb-1 mb-3">
                <div className="col">
                  <h5 className="mb-0 font-20 mt-4">
                    {t("Organizations Registration Requests")}
                  </h5>
                </div>
              </div>
              <div className="ml-3">
                {requests?.map((item, index) => (
                  <Card
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      marginBottom: "15px",
                    }}
                    key={index}
                  >
                    {/* Left Side: Avatar */}
                    <Avatar
                      alt="Organization Logo"
                      src="/assets/images/imgred.png"
                      style={{
                        width: 70,
                        height: 70,
                        marginRight: "15px",
                      }}
                    />

                    {/* Middle Content: Organization Details */}
                    <CardContent style={{ flexGrow: 1 }}>
                      <Typography variant="h6" component="div">
                        {item.companyName}
                      </Typography>
                    </CardContent>

                    {/* Right Side: Action Buttons */}
                    <Box>
                      <span style={{ marginRight: 6 }}>
                        <ViewDetails data={item} />
                      </span>

                      <button
                        className="btn btn-success2 px-3 px-3"
                        style={{ marginRight: "5px" }}
                        onClick={() => approve(item.email)}
                        disabled={
                          loadingStates[item.email]?.approveLoading ||
                          action === "reject"
                        } // Individual loading state
                      >
                        {loadingStates[item.email]?.approveLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          t("Approve")
                        )}
                      </button>

                      <button
                        className="btn btn-danger2 px-3"
                        style={{ marginRight: "5px" }}
                        onClick={() => handleRejectWithReason(item.email)}
                        disabled={
                          loadingStates[item.email]?.rejectLoading ||
                          action === "approve"
                        } // Individual loading state
                      >
                        {loadingStates[item.email]?.rejectLoading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          t("Reject")
                        )}
                      </button>
                    </Box>
                  </Card>
                ))}
              </div>
              <div className="row">
                {" "}
                <div className="col-12 col-lg-12 col-xl-12 px-2 text-center mt-4">
                  <a
                    href="/dashboard/admin/companies/viewrequests"
                    className="text-blue font-16 ms-2 mb-0"
                  >
                    {t("View all")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Company Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12 mb-3">
              {/* <div className="px-4 pt-4 text-center">
                <img src="/assets/img/avatardefault_92824.png" style={{ width: "100px", height: "auro" }} />
              </div> */}
              <div className="px-lg-4 pt-5 px-3">
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Company name
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {requests?.name}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Phone number
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {requests?.number}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50"> Email</span>
                      <span className="font-14 text-gray text-right w_50 ">
                        {" "}
                        {requests?.email}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mb-3">
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark w_50">
                        {" "}
                        Personal/Social security number
                      </span>
                      <span className="font-14 text-gray text-right w_50">
                        {" "}
                        {requests?.ssn}
                      </span>
                    </div>
                  </div>
                </div>
                {/* {location.pathname === "/dashboard/admin/companies" ? (
                  <div className="row">
                    <div className="col-md-6 col-12  mb-3">
                      <div className="border-bottom2 height-50">
                        <span className="font-14 text-dark w_50">
                          {" "}
                          Corporate Reg no.
                        </span>
                        <span className="font-14 text-gray text-right w_50">
                          {" "}
                          {requests.registrationNo}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-12  mb-3">
                      <div className="border-bottom2 height-50">
                        <span className="font-14 text-dark w_50">
                          {" "}
                          Tax Agency no.
                        </span>
                        <span className="font-14 text-gray text-right w_50">
                          {" "}
                          {requests.taxAgencyno}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Reason Modal */}
      <Modal
        show={reasonModalOpen}
        onHide={handleReasonModalClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Enter Reason for Rejection")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label={t("Reason for Rejection")}
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleReasonModalClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmReject}
            disabled={!rejectionReason.trim()} // Disable if reason is empty
            sx={{ ml: 2 }}
          >
            {t("Confirm Reject")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminHome;
