import { jwtDecode } from "jwt-decode";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";

const SideNav = () => {
  const token = localStorage.getItem("token");
  const jwt = token ? jwtDecode(token) : null;
  //const jwt = jwtDecode(localStorage.getItem("token"));
  const role = jwt?.userType;
  const menuSetting = useRef(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (!menuSetting.current) {
      var script = document.createElement("script");
      var menuCode = `$(document).ready(function(){
        $("#menu").metisMenu()
      });`;
      script.innerHTML = menuCode;
      document.body.appendChild(script);
      menuSetting.current = true;
    }
  }, []);

  const adminMenu = [
    {
      path: "/dashboard/admin/home",
      title: t("Home"),
      icon: "bi bi-house-door",
    },
    {
      path: "/dashboard/admin/companies",
      title: t("Companies"),
      icon: "fadeIn animated bx bx-buildings",
    },
    // {
    //   path: "/dashboard/admin/plans",
    //   title: t("Plans"),
    //   icon: "fadeIn animated bx bx-file-blank",
    // },

    {
      path: "",
      title: t("Plans"),
      icon: "fadeIn animated bx bx-file-blank",
      child: [
        {
          path: "/dashboard/admin/plans/list",
          title: t("Subscription Plans"),
        },
        {
          path: "/dashboard/admin/enterprise/list",
          title: t("Enterprise plans"),
        },
      ],
    },
    {
      path: "/dashboard/admin/billingpayments",
      title: t("Billing and Payment"),
      icon: "fadeIn animated bx bx-credit-card",
    },
    {
      path: "/dashboard/admin/contacts",
      title: t("Contacts"),
      icon: "fadeIn animated bx bx-user-voice",
    },
    {
      path: "",
      title: t("Manage CMS"),
      icon: "lni lni-cog",
      child: [
        {
          path: "/dashboard/admin/settings/blog",
          title: t("Blog"),
        },
        {
          path: "/dashboard/admin/settings/tutorials",
          title: t("Tutorials"),
        },
      ],
    },

    {
      path: "",
      title: t("Settings"),
      icon: "lni lni-cog",
      child: [
        {
          path: "/dashboard/admin/settings/smtp",
          title: "SMTP",
        },
        {
          path: "/dashboard/admin/settings/SettingCompany",
          title: t("Company Setting"),
        },
        {
          path: "/dashboard/admin/settings/devloperSetting",
          title: t("Developer Setting"),
        },
        // {
        //   path: "/dashboard/admin/settings/dateFormat",
        //   title: t("Date Format"),
        // },
        {
          path: "/dashboard/admin/settings/language",
          title: t("Language Preference"),
        },
        {
          path: "/dashboard/admin/settings/appSetting",
          title: t("Application Settings"),
        },
        // {
        //   path: "/dashboard/admin/settings/workerskill",
        //   title: t("Skills"),
        // },
      ],
    },
    // {
    //   path: "/",
    //   title: "Logout",
    //   icon: "fadeIn animated bx bx-log-out-circle",
    // },
  ];
  return (
    <>
      {jwt !== null ? (
        <aside className="sidebar-wrapper" data-simplebar="true">
          <div className="sidebar-header">
            <a href={`/dashboard/admin/home`}>
              <img
                src="/assets/images/brand-logo-2.png"
                className="logo-icon"
                alt="logo icon"
              />
            </a>
          </div>

          <ul className="metismenu" id="menu">
            {role === "SUPERADMIN"
              ? adminMenu.map((item, i) => (
                  <li key={i}>
                    {item.child && item.child.length > 0 ? (
                      <a href="javascript:;" className="has-arrow">
                        <div className="parent-icon">
                          <i className={item.icon}></i>
                        </div>
                        <div className="menu-title">{item.title}</div>
                      </a>
                    ) : (
                      <NavLink to={item.path}>
                        <div className="parent-icon">
                          <i className={item.icon}></i>
                        </div>
                        <div className="menu-title">{item.title}</div>
                      </NavLink>
                    )}

                    {item.child ? (
                      <ul>
                        {item.child.map((child) => (
                          <li key={child.path}>
                            <NavLink to={child.path}>{child.title}</NavLink>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))
              : null}
          </ul>
        </aside>
      ) : null}
    </>
  );
};

export default SideNav;
