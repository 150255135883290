import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ServerSideTable from "../../../common components/serverSideTable";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { t } from "i18next";
import PlanApi from "./services";
import { Form, Modal } from "react-bootstrap";
import { isValidName, isValidNumber } from "../../../../helper/regex";

const EnterPrisePlans = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [name, setName] = useState("");
  const [sortBy, setSortBy] = useState("createdAt"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const [planType, setPlanType] = useState("");

  const onChangePlanType = (e) => {
    const planType = e.target.value;
    setPlanType(planType);
  };

  const handleSearch = () => {
    setPage(0);
    changeList();
  };

  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  function changeList() {
    setLoading(true);
    PlanApi.getEnterprisePlans(
      page,
      pageSize,
      name,
      planType,
      sortBy,
      sortDir
    ).then((response) => {
      const { totalPages, totalElements, currentPage, content } =
        response.data.data;
      setCount(totalPages);
      setTotalItems(totalElements);
      setData(content);
      setLoading(false);
    });
  }

  useEffect(() => {
    getCompanies();
  }, []);
  useEffect(() => {
    changeList();
  }, [page, pageSize, sortBy, sortDir]);

  const dataJson = {
    planName: "",
    price: "",
    priceOfPlan: "",
    shareCount: "",
    validityDays: "",
    features: [],
  };

  const [show, setShow] = useState(false);
  const [planData, setPlanData] = useState(dataJson);
  const [selectedEditFeatures, setSelectedEditFeatures] = useState([]);
  const [changesMade, setChangesMade] = useState(true);
  const [validated, setValidated] = useState(false);
  const [features, setFeatures] = useState([]);

  const columns = [
    { field: "planId", headerName: t("Plan Id"), width: 100 },
    { field: "planName", headerName: t("Name"), width: 200 },
    { field: "planType", headerName: t("Plan Type"), width: 200 },
    { field: "price", headerName: t("Price"), width: 100 },
    { field: "validityDays", headerName: t("Valid Days"), width: 100 },
    { field: "shareCount", headerName: t("No of Users"), width: 150 },
    {
      field: "view",
      headerName: t("Action"),
      sortable: false,
      renderCell: (param) => {
        return (
          <button
            className="btn btn-primary"
            onClick={() => handleViewDetails(param.row)}
          >
            {t("Edit")}
          </button>
        );
      },
      width: 120,
    },
  ];

  const getAllPlansFeatures = () => {
    PlanApi.getAllPlansFeatures()
      .then((response) => {
        setFeatures(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getAllPlansFeatures();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleModelClose = () => {
    setShow(false);
    setValidated(false);
  };

  const handleViewDetails = (data) => {
    setPlanData(data);
    const selectedFeatureIds = data?.features?.map(
      (feature) => feature.featureId
    );
    setSelectedEditFeatures(selectedFeatureIds);
    setShow(true);
    setTimeout(() => {
      console.log(planData);
    }, 500);
  };

  function handleChange(e) {
    debugger;
    const { name, value } = e.target;
    // Function to validate input based on field name
    const isValidInput = (name, value) => {
      switch (name) {
        case "planName":
          return isValidName(value);
        case "price":
        case "priceOfPlan":
        case "shareCount":
        case "validityDays":
          return isValidNumber(value);
        default:
          return true; // If no specific validation, consider it valid
      }
    };

    // Validate the input and return early if invalid
    if (!isValidInput(name, value)) {
      return;
    }
    setPlanData({
      ...planData,
      [name]: value,
    });
    setChangesMade(false);
  }

  function handleEditCheckboxChange(featureId) {
    setSelectedEditFeatures((prevSelectedFeatures) =>
      prevSelectedFeatures.includes(featureId)
        ? prevSelectedFeatures.filter((id) => id !== featureId)
        : [...prevSelectedFeatures, featureId]
    );
    setChangesMade(false);
  }

  const handleSubmit = (event) => {
    // Prevents the default form submission behavior
    setValidated(false);
    const form = event.currentTarget;

    // Checking form validity
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // Checking if all required fields are filled
      if (
        planData?.planName?.trim() !== "" &&
        planData.price !== "" &&
        planData.priceOfPlan !== "" &&
        planData.shareCount !== "" &&
        planData.validityDays !== "" &&
        selectedEditFeatures.length >= 3
      ) {
        updateData();
      } else {
        setOpen(true);
        setMessage("Fill all required fields");
        setSeverity("error");
      }
    }
    setValidated(true);
  };

  function updateData() {
    // Prepare features data
    const updatedFeatures = selectedEditFeatures.map((featureId) => ({
      featureId,
      features: features.find((f) => f.featureId === featureId)?.features || "",
    }));

    PlanApi.updatepricePlann(planData.planId, {
      ...planData,
      features: updatedFeatures,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setOpen(true);
          setMessage(response.data.message);
          setSeverity("success");
          handleModelClose();
          setChangesMade(true);
          changeList();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const initialEnterprisePlan = {
    planName: "",
    price: "",
    priceOfPlan: "",
    planType: "",
    shareCount: "",
    validityDays: "",
    features: [],
  };

  const [enterprisePlan, setEnterprisePlan] = useState(initialEnterprisePlan);

  const [showEnterpricePlan, setShowEnterpricePlan] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const handleEnterpricePlanClose = () => setShowEnterpricePlan(false);

  const handleEnterpricePlanShow = () => {
    setShowEnterpricePlan(true);
  };

  const handleCheckboxChange = (featureId) => {
    setSelectedFeatures((prevSelectedFeatures) => {
      if (prevSelectedFeatures.includes(featureId)) {
        return prevSelectedFeatures.filter((id) => id !== featureId);
      } else {
        return [...prevSelectedFeatures, featureId];
      }
    });
  };

  // const handleEnterpricePlanChange = (e) => {
  //   const { name, value } = e.target;
  // };

  const handleEnterpricePlanChange = (e) => {
    debugger;
    const { name, value } = e.target;
    // Function to validate input based on field name
    const isValidInput = (name, value) => {
      switch (name) {
        case "planName":
          return isValidName(value);
        case "price":
        case "priceOfPlan":
        case "shareCount":
        case "validityDays":
          return isValidNumber(value);
        default:
          return true; // If no specific validation, consider it valid
      }
    };

    // Validate the input and return early if invalid
    if (!isValidInput(name, value)) {
      return;
    }
    setEnterprisePlan({
      ...enterprisePlan,
      [name]: value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior

    const form = event.currentTarget;

    // Checking form validity
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // Checking if all required fields are filled
    if (
      enterprisePlan.planName.trim() !== "" &&
      enterprisePlan.price !== "" &&
      enterprisePlan.priceOfPlan !== "" &&
      enterprisePlan.shareCount !== "" &&
      enterprisePlan.validityDays !== "" &&
      selectedFeatures.length >= 3 // Check if at least 3 features are selected
    ) {
      // Prepare features data
      const updatedFeatures = selectedFeatures.map((featureId) => ({
        featureId,
        features:
          features.find((f) => f.featureId === featureId)?.features || "",
      }));

      // Create the updated enterprise plan
      const updatedEnterprisePlan = {
        ...enterprisePlan,
        features: updatedFeatures,
      };

      // Call the API
      PlanApi.saveEnterprisePlan(updatedEnterprisePlan)
        .then((response) => {
          setEnterprisePlan(initialEnterprisePlan);
          setSelectedFeatures([]);
          setOpen(true);
          setMessage("Plan created successfully.");
          setSeverity("success");
          console.log("Enterprise Plan created");
          handleEnterpricePlanClose();
          changeList();
        })
        .catch((error) => {
          setOpen(true);
          setMessage("Something went wrong!");
          setSeverity("error");
          console.log("error", error);
        });
    } else {
      if (selectedFeatures.length < 3) {
        setOpen(true);
        setMessage("Please select at least 3 features");
        setSeverity("error");
      } else {
        setOpen(true);
        setMessage("Fill all required fields");
        setSeverity("error");
      }
    }

    setValidated(true);
  };

  useEffect(() => {
    console.log("enterprisePlan", enterprisePlan);
  }, [enterprisePlan]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">{t("Enterprise plans")}</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-6 text-right">
                  <Button
                    variant="contained"
                    onClick={handleEnterpricePlanShow}
                  >
                    {t("Create New Enterprise Plan")}
                  </Button>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
                <div className="col-12 col-lg-4 col-xl-4">
                  <TextField
                    fullWidth
                    size="small"
                    id="name"
                    name="name"
                    label={t("Search by Plan Name")}
                    variant="outlined"
                    value={name}
                    onChange={onChangeName}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="col-12 col-lg-3 col-xl-3">
                  <FormControl fullWidth size="small">
                    <Select
                      id="plan-type"
                      value={planType}
                      onChange={onChangePlanType}
                      onKeyDown={handleKeyDown}
                      displayEmpty
                      inputProps={{ "aria-label": "Plan Type" }}
                    >
                      <MenuItem value={""}>All</MenuItem>
                      <MenuItem value={"Monthly"}>Monthly</MenuItem>
                      <MenuItem value={"Yearly"}>Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-lg-3 col-xl-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                  >
                    {t("SEARCH")}
                  </Button>
                </div>
              </div>

              <ServerSideTable
                pageSize={pageSize}
                page={page}
                sortBy={sortBy}
                sortDir={sortDir}
                count={count}
                functionName={getCompanies}
                data={data}
                columns={columns}
                loading={loading}
                idname="planId"
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleModelClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Edit_Plan")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>{t("Plan Name")}</Form.Label>
              <Form.Control
                required
                type="text"
                name="planName"
                maxLength={25}
                value={planData.planName}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter plan name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>{t("Price")} ($)</Form.Label>
              <Form.Control
                required
                type="text"
                name="price"
                maxLength={5}
                value={planData.price}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter price
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>{t("Price of Plan")}</Form.Label>
              <Form.Control
                required
                type="text"
                maxLength={5}
                name="priceOfPlan"
                value={planData.priceOfPlan}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter plan price
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>{t("Share Count")}</Form.Label>
              <Form.Control
                required
                type="text"
                name="shareCount"
                maxLength={6}
                value={planData.shareCount}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter share count
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="mb-3">
              <Form.Label>{t("Validity Days")}</Form.Label>
              <Form.Control
                required
                type="text"
                name="validityDays"
                maxLength={3}
                value={planData.validityDays}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter validity date
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationCustomplan08" className="mb-3">
              <Form.Label>{t("List of Features")}</Form.Label>
              {features.map((item) => (
                <Form.Check
                  type="checkbox"
                  key={item.featureId}
                  id={`checkbox-${item.featureId}`}
                  label={item.features}
                  checked={selectedEditFeatures.includes(item.featureId)}
                  onChange={() => handleEditCheckboxChange(item.featureId)}
                />
              ))}
              {validated && selectedEditFeatures.length < 3 && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  Please select at least 3 features
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleModelClose}
          >
            {t("Close")}
          </Button>
          <Button
            sx={{ ml: 2 }}
            disabled={changesMade}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {t("Update")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEnterpricePlan}
        onHide={handleEnterpricePlanClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Add New Enterprise Plan")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group controlId="validationCustomplan01" className="mb-3">
              <Form.Label>{t("Plan Name")}</Form.Label>
              <Form.Control
                required
                type="text"
                name="planName"
                placeholder={t("Plan Name")}
                maxLength={45}
                value={enterprisePlan.planName}
                onChange={handleEnterpricePlanChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter plan name
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationCustomplan02" className="mb-3">
              <Form.Label>{t("Plan Type")}</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="planType"
                value={enterprisePlan.planType}
                onChange={handleEnterpricePlanChange}
              >
                <option value="">{t("Select Plan Type")}</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Select plan type
              </Form.Control.Feedback>
            </Form.Group>

            {/* <Form.Group controlId="validationCustomplan03" className="mb-3">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                required
                type="text"
                name="currency"
                placeholder="Currency"
                value={enterprisePlan.currency}
                onChange={handleEnterpricePlanChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter currency
              </Form.Control.Feedback>
            </Form.Group> */}

            <Form.Group controlId="validationCustomplan04" className="mb-3">
              <Form.Label>{t("Price")} ($)</Form.Label>
              <Form.Control
                required
                type="text"
                name="price"
                placeholder={t("Plan Price")}
                maxLength={5}
                value={enterprisePlan.price}
                onChange={handleEnterpricePlanChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter price
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustomplan05" className="mb-3">
              <Form.Label>{t("Price of Plan")}</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder={t("Price of Plan")}
                maxLength={5}
                name="priceOfPlan"
                value={enterprisePlan.priceOfPlan}
                onChange={handleEnterpricePlanChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter plan price
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustomplan06" className="mb-3">
              <Form.Label>{t("Share Count")}</Form.Label>
              <Form.Control
                required
                type="text"
                name="shareCount"
                placeholder={t("Share Count")}
                maxLength={6}
                value={enterprisePlan.shareCount}
                onChange={handleEnterpricePlanChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter share count
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustomplan07" className="mb-3">
              <Form.Label>{t("Validity Days")}</Form.Label>
              <Form.Control
                required
                type="text"
                name="validityDays"
                maxLength={3}
                placeholder={t("Validity Days")}
                value={enterprisePlan.validityDays}
                onChange={handleEnterpricePlanChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter validity date
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationCustomplan08" className="mb-3">
              <Form.Label>{t("List of Features")}</Form.Label>
              {features.map((item) => (
                <Form.Check
                  type="checkbox"
                  key={item.featureId}
                  id={`checkbox-${item.featureId}`}
                  label={item.features}
                  checked={selectedFeatures.includes(item.featureId)}
                  onChange={() => handleCheckboxChange(item.featureId)}
                />
              ))}
              {validated && selectedFeatures.length < 3 && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  Please select at least 3 features
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleEnterpricePlanClose}
          >
            {t("Close")}
          </Button>
          <Button
            variant="contained"
            onClick={handleFormSubmit}
            sx={{ marginLeft: 2 }}
          >
            {t("Save")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default EnterPrisePlans;
