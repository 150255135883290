import { useEffect, useState } from "react";
import SettingApi from "./services";
import { Form } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { isValidName, isValidPhoneNo } from "../../../../helper/regex";

const SettingCompany = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [changesMade, setChangesMade] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const dataJson = {
    name: "",
    address: "",
    phoneNo: "",
    supportEmail: "",
    skypeId: "",
    regYear: "",
  };
  const [data, setData] = useState(dataJson);

  function setValue(e) {
    const { name, value } = e.target;

    if (name === "name" && !isValidName(value)) {
      return;
    }

    if (name === "phoneNo" && !isValidPhoneNo(value)) {
      return;
    }
    if (name === "regYear" && !isValidPhoneNo(value)) {
      return;
    }

    // Update the state with the new value
    setData({
      ...data,
      [name]: value,
    });
    setChangesMade(false);
  }

  function companyGet() {
    debugger;
    SettingApi.companyGet().then((respons) => {
      const responsData = respons.data.data;
      setData({
        ...data,
        name: responsData.name,
        address: responsData.address,
        phoneNo: responsData.phoneNo,
        supportEmail: responsData.supportEmail,
        skypeId: responsData.skypeId,
        regYear: responsData.regYear,
      });
    });
  }
  function companyUpdate() {
    // Check if all required fields are empty
    if (
      data?.name?.trim() === "" ||
      data?.address?.trim() === "" ||
      data?.phoneNo?.trim() === "" ||
      data?.supportEmail?.trim() === "" ||
      data?.regYear?.trim() === "" ||
      data?.skypeId?.trim() !== ""
    ) {
      // If any required field is empty, show error message
      setMessage("Fill all required fields.");
      setSeverity("error");
      setOpen(true);
    } else {
      // Email validation
      const emailRegex = /^[a-z._\d]+@[a-z0-9]+\.[a-z0-9]{2,}$/i; // Case-insensitive match
      if (!emailRegex.test(data.supportEmail.trim())) {
        // If the email is not valid, show error message
        setMessage("Enter a valid email");
        setSeverity("error");
        setOpen(true);
        return;
      }

      // Skype ID validation
      if (data.skypeId.trim() === "" && data.skypeId !== "") {
        // If the skypeId is only spaces, show error message
        setMessage("Skype ID cannot contain only spaces");
        setSeverity("error");
        setOpen(true);
        return;
      } else {
        // All required fields are filled and email is valid, proceed with updating
        SettingApi.companyUpdate(data)
          .then((respons) => {
            console.log(respons);
            if (respons.data.status === true) {
              setOpen(true);
              setMessage(respons.data.message);
              setSeverity("success");
              setChangesMade(true);
            }
          })
          .catch((e) => {
            if (e.response && e.response.status === 404) {
              setOpen(true);
              setMessage(e.response.data.message);
              setSeverity("error");
            }
          });
      }
    }
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      companyUpdate();
    }
    setValidated(true);
  };
  useEffect(() => {
    companyGet();
  }, []);

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-12 col-lg-12">
              <h5 className="mb-0">{t("Company Information")}</h5>
            </div>
          </div>
        </div>
        <div className="card-body profile">
          <Form noValidate validated={validated}>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Name")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Name"
                    value={data.name}
                    name="name"
                    maxLength={35}
                    onChange={(e) => setValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter name
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-8 col-lg-8 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Address")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Address"
                    value={data.address}
                    name="address"
                    maxLength={100}
                    onChange={(e) => setValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter address
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Phone No")}</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={16}
                    value={data.phoneNo}
                    name="phoneNo"
                    onChange={(e) => setValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    Enter phone number
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Support Email")}</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    maxLength={55}
                    placeholder="Support Email"
                    value={data.supportEmail}
                    name="supportEmail"
                    onChange={(e) => setValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter email
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Skype Id")}</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={35}
                    placeholder="Skype Id"
                    value={data.skypeId}
                    name="skypeId"
                    onChange={(e) => setValue(e)}
                  />
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Registration Year")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Registration Year"
                    value={data.regYear}
                    min="1900"
                    max="2099"
                    maxLength={4}
                    name="regYear"
                    onChange={(e) => setValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter registration year
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </Form>

          <div className="row">
            <div className="col-12 col-md-4 col-lg-4 mb-4">
              <button
                disabled={changesMade}
                class="btn btn-primary mt-4 px-4"
                onClick={handleSubmit}
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default SettingCompany;
