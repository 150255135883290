import React from "react";
import { useEffect, useState } from "react";
import PaymentsApi from "./services";
import { Button, Chip, TextField } from "@mui/material";
import ServerSideTable from "../../../common components/serverSideTable";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { t } from "i18next";

const BillingPayment = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [sortBy, setSortBy] = useState("createdAt"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir

  const onTransactionIdChange = (e) => {
    const transactionId = e.target.value;
    setTransactionId(transactionId);
  };

  const handleSearch = () => {
    changeList();
  };

  function getBillings(e) {
    debugger;
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  function changeList() {
    setLoading(true);
    PaymentsApi.getBillings(page, pageSize, transactionId, sortBy, sortDir)
      .then((response) => {
        const { totalPages, totalElements, currentPage, content } =
          response.data.data;
        setCount(totalPages);
        setTotalItems(totalElements);
        setData(content);
        console.log("payments_content", data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  }

  useEffect(() => {
    getBillings();
  }, []);

  useEffect(() => {
    changeList();
  }, [page, pageSize, sortBy, sortDir]);

  const columns = [
    {
      field: "id",
      headerName: t("Bill Id"),
      width: 120,
      align: "left",
      marginRight: "2rem",
    },
    {
      field: "planId",
      headerName: t("Subscription"),
      width: 150,
      align: "left",
    },
    {
      field: "transactionId",
      headerName: t("Transaction Id"),
      width: 220,
      align: "left",
    },
    {
      field: "paymentMethodTypes",
      headerName: t("Payment Method"),
      width: 200,
      align: "left",
      sortable: false,
    },
    {
      field: "amount",
      headerName: t("Amount"),
      width: 90,
      align: "left",
      valueFormatter: (params) => {
        // Ensure the value is a number and format it to two decimal places
        return params.value ? params.value.toFixed(2) : "";
      },
    },
    {
      field: "status",
      headerName: t("Payment Status"),
      width: 200,
      sortable: false,
      renderCell: (param) => {
        return (
          <Chip
            label={param.row.paymentStatus}
            color={
              param.row.paymentStatus == "paid"
                ? "success"
                : param.row.paymentStatus == "pending"
                ? "warning"
                : param.row.paymentStatus == "canceled"
                ? "error"
                : null
            }
            style={{ width: "7rem " }}
          />
        );
      },
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setShow(false);
    setValidated(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">{t("Billing/Payments")}</h5>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
                <div className="col-12 col-lg-4 col-xl-4">
                  <TextField
                    fullWidth
                    size="small"
                    id="transactionId"
                    name="transactionId"
                    label={t("Search by Transaction Id")}
                    variant="outlined"
                    value={transactionId}
                    onChange={onTransactionIdChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="col-12 col-lg-3 col-xl-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                  >
                    {t("SEARCH")}
                  </Button>
                </div>
              </div>
              <ServerSideTable
                pageSize={10}
                page={0}
                sortBy={sortBy}
                sortDir={sortDir}
                count={count}
                functionName={getBillings}
                data={data}
                columns={columns}
                loading={loading}
                idname="id"
                componentsProps={{
                  header: {
                    style: {
                      textAlign: "center",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default BillingPayment;
